import React from 'react';
import { makeStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { navigate } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.spacing(4),
    textTransform: 'none',
    color: '#040068',
    lineHeight: '27px',
    fontWeight: 400,
    fontSize: '16px',
    fontFamily: 'PangramSansMed, sans-serif',
    visibility: ({ hide }) => hide ? 'hidden' : '',
    marginTop: ({ gutterTop }) => {
      if (gutterTop === true) return theme.spacing(2.5);
      if (typeof gutterTop === 'number') return theme.spacing(gutterTop);
      return 0;
    },
  },
  loadingSpinner: {
    marginLeft: theme.spacing(1),
  },
  branded: {
    background: 'linear-gradient(180deg, #B670FF 0%, #FFC5FF 100%)',
    color: '#040068',
    boxShadow: 'none',
    minWidth: 150,
  },
  sizeSmall: {
    padding: '12px 24px',
  },
  sizeLarge: {
    padding: '8px 24px',
    fontSize: '20px',
    lineHeight: '38px'
  },
  contained: {
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08)',
  },
  containedPrimary: {
    background: 'linear-gradient(180deg, #B670FF 0%, #FFC5FF 100%)',
    color: '#040068',
    boxShadow: 'none',
    '&$disabled': {
      background: '#E8E8E8',
    }
  },
  containedSecondary: {
    backgroundColor: '#fff',
    color: '#040068',
    border: `1px solid #040068`,
    '&:hover': {
      backgroundColor: '#040068',
      color: '#fff',
    }
  },
  outlined: {
    background: 'transparent',
    border: `1px solid #040068`
  },
  text: {
    padding: '6px 8px',
    margin: '-6px -8px',
    textDecoration: 'underline',
  }
}))

const themes = {
  primary: {
    color: 'primary',
    variant: 'contained',
  },
  secondary: {
    color: 'secondary',
    variant: 'contained',
  },
  callout: {
    variant: 'outlined'
  },
  link: {
    variant: 'text',
  },
  wide: {
    fullWidth: true,
  },
  large: {
    size: 'large',
  },
  small: {
    size: 'small',
  },
}

const Button = (props) => {
  const {
    children,
    theme = 'primary',
    branded,
    gutterTop = 0,
    isLoading = false,

    href,
    onClick,

    hide = false,
    wide = false,
    large = false,
    small = false,

    classes,
    className,
    ...otherProps
  } = props;

  const classStyles = useStyles({ gutterTop, hide });

  const classList = clsx(
    classStyles.root,
    classes,
    className,
    branded && classStyles.branded,
  );

  const internalProps = { ...themes[theme] };
  if (large) Object.assign(internalProps, themes.large);
  if (small) Object.assign(internalProps, themes.small);
  if (wide) Object.assign(internalProps, themes.wide);

  if (isLoading) otherProps.disabled = true;

  const handleClick = e => {
    if (typeof onClick === 'function') onClick(e);

    if (typeof href === 'string') {
      const isHttp = href.startsWith('http:') || href.startsWith('https:');
      const isMail = href.startsWith('mailto:');
      const isNumb = href.startsWith('tel:');

      if (!isHttp && !isMail && !isNumb) {
        navigate(href);
        e.preventDefault();
      }
    }
  }

  return (
    <MuiButton
      { ...internalProps }
      {...otherProps}
      className={ classList }
      classes={{
        sizeSmall: classStyles.sizeSmall,
        sizeLarge: classStyles.sizeLarge,
        contained: classStyles.contained,
        containedPrimary: classStyles.containedPrimary,
        containedSecondary: classStyles.containedSecondary,
        outlined: classStyles.outlined,
        text: classStyles.text,
      }}

      href={href}
      onClick={handleClick}>
        { children }
        { isLoading && <CircularProgress size={16} className={ classStyles.loadingSpinner } /> }
    </MuiButton>
  );
}

export default Button;