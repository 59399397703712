import React from 'react';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import Typography from './Typography';
import { Box } from '@mui/material';

const Menu = (props) => {

  const {
    items,
    open,
    anchorEl,
    handleClose,
  } = props

  return (
    <MuiMenu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id="demo-customized-menu"
      MenuListProps={{
        'aria-labelledby': 'demo-customized-button',
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          backgroundColor: 'transparent',
        },
        '.MuiMenu-list': {
          backgroundColor: '#fff7f4',
          borderRadius: '0 0 20px 20px',
          overflow: 'hidden',
          minWidth: '200px',
        }
      }}
      {...props}
    >
      {
        (items) && items.map((item) => (
          <>
            <MuiMenuItem
              key={item.name}
              component='a'
              href={item.href}
              sx={{
                color: '#040068',
                fontFamily: 'PangramSansMed',
                pt: 2,
                pb:0,
                '&:hover': {
                  backgroundColor: '#ffefe9',
                }
              }}
              disableRipple
            >
              {item.name}      
            </MuiMenuItem>
            {item.helptext &&
            <Box sx={{pl:3, pt:0, pb:1, maxWidth: '250px', color: '#040068'}}>
              <Typography type='label' preset={2}>
                {item.helptext}
              </Typography>
            </Box>}
          </>
          ))
      }
    </MuiMenu>
  );
}

export default Menu;
