import React, {useEffect} from 'react'

import '../fonts/fonts.css';
import Box from '@mui/material/Box';

import Seo from './Seo';

import Header from './Header';
import Section from './Section';
import Footer from './Footer';

const Page = (props) => {
  const {
    title = 'Home',
    headerRef,
    children,
  } = props;

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  return (
    <>
      <Seo
        title={ title }
      />
      <Box sx={{
        fontFamily: 'PangramSansMed, PangramSansRounded',
        backgroundColor: "#FFFFFF",
        color: "#040068",
      }}>
        <Header ref={headerRef}/>
        {children}

        {/* Footer */}
        <Section theme='primary' variant='narrow'>
          <Footer />
        </Section>
      </Box>
    </>
  )
}

export default Page