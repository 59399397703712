import React, { useState, forwardRef, useImperativeHandle } from 'react';
import logo from '../images/logo_navy.svg';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import Button from './Button';
import Typography from './Typography';
import Menu from './Menu';
import ContactForm from './ContactForm';

const navigationLinks = [
  {
    name: 'Your Solution',
    theme: 'sub',
    sub: [
      {
        name: 'For Clinicians',
        href: '/for-clinicians',
      },
      {
        name: 'For Workplaces',
        href: '/for-workplaces',
      }
    ]
  },
  {
    name: 'Our Story',
    theme: 'sub',
    sub: [
      {
        name: 'Our Origins',
        href: '/our-origins',
      },
      {
        name: 'How we work',
        href: '/how-we-work',
      },
      {
        name: 'Who we are',
        href: '/who-we-are',
      },
    ]
  },
  {
    name: 'Resources',
    theme: 'sub',
    sub: [
      {
        name: 'Media',
        href: '/media',
      },
      {
        name: 'Library',
        href: '/library',
      },
    ]
  },
  {
    name: 'Sign in',
    theme: 'secondary',
    sub: [
      {
        name: 'Sign in to Clinical platform',
        href: 'https://platform.innowell.org',
        helptext: 'Used by patients, practitioners, and administrators in a health service'
      },
      {
        name: 'Sign in to Individual platform',
        href: 'https://individual.innowell.org',
        helptext: 'Used by students, employees, or members in an organisation'
      },
    ]
  },
  {
    name: 'Book a demo',
    theme: 'primary',
  },
]

const Header = forwardRef((props, ref) => {
  const { window } = props;

  const [mobileOpen, setMobileOpen] = useState(false);
  const [isBookNowOpen, setIsBookNowOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);

  const openSub = Boolean(anchorEl);

  const handleClickMenu = (event) => {
    setAnchorEl({el: event.currentTarget, data: event.currentTarget.dataset});
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setIsBookNowOpen(true);
  }

  const handleCloseDialog = () => {
    setIsBookNowOpen(false);
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useImperativeHandle(ref, () => ({
    triggerBookNow: () => {
      handleOpenDialog();
    }
  }));

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <List sx={{padding:'32px 32px 56px'}}>
        {navigationLinks.map((item) => {

          return (
          <ListItem
            key={item.name}
            disablePadding
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginBottom: 2,
            }}>
            {
              (item.theme !== 'sub') ?
              <Button
                theme={item.theme}
                href={item.href}
                onClick={(item.name === 'Book a demo') ? handleOpenDialog : undefined}
                style={{textDecoration: 'none',}}
                disableRipple
              >
                {item.name}
              </Button>
              :
              <Typography type='heading' preset={4} disableMargin style={{marginBottom: 0}}>{item.name}</Typography>
            }

            {item.sub?.map((subItem) => (
                <>
                  <Button key={subItem.name} href={subItem.href} theme='text' style={{marginLeft: 16, textDecoration: 'none', padding: 12}} disableRipple>
                    {subItem.name}
                  </Button>
                  {subItem.helptext &&
                    <Box sx={{pl:6, pt:0, pb:0, maxWidth: '250px', color: '#040068'}}>
                    <Typography type='label' preset={2}>
                      {subItem.helptext}
                    </Typography>
                  </Box>
                  }
                </>
              ))
            }
          </ListItem>
        )})}
      </List>
    </Box>
  );

  const bookNowDialog = (
    <Box>
      <Dialog
        maxWidth='lg'
        sx={{
          fontFamily: 'PangramSansMed, Helvetica, Arial, sans-serif',
          '& .MuiDialog-paper': {
            color: '#040068',
          }
        }}
        open={isBookNowOpen}
        onClose={handleCloseDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <Typography type='heading' preset={1}>Book a demo</Typography>
          <Typography>
            Want to explore how the Innowell platform can improve your organisation’s mental fitness, enable you to deliver enhanced clinical care, or help strengthen your own mental wellbeing?
          </Typography>
          <Typography>
            Send us your details and we'll be in touch soon.
          </Typography>
          <Typography>
            Please note, we are not a crisis service.
          </Typography>
          <ContactForm afterSuccess={handleCloseDialog}/>
        </DialogContent>
      </Dialog>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <AppBar
          component='nav'
          elevation={0}
          sx={{
            backgroundColor: '#FFFBFA',
            color: '#040068',
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)'
          }}
        >
          <Container
            maxWidth={'lg'}
          >
            <Toolbar sx={{justifyContent: 'space-between'}}>

              {/* Menu button */}
              <IconButton
                color='inherit'
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { md: 'none' },
                  position: 'absolute',
                }}
              >
                <MenuIcon />
              </IconButton>

              {/* Logo */}
              <Box
                sx={{
                  width: {xs:'122px', md: '168px'},
                  display: 'block',
                  margin: {xs: '0 auto', md: '0'},
                  'a': {
                    textDecoration: 'none',
                    color: 'initial',
                  }
                }}
              >
                <a href="/"><img src={logo} alt='innowell logo'/></a>
              </Box>

              {/* Desktop nav */}
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    md: 'flex'
                  },
                }}
              >
                {navigationLinks.map((item) => {
                  const action = { href: item.href, onClick: (!item.href) ? handleClickMenu : () => undefined}
                  if (item.name === 'Book a demo') action.onClick = handleOpenDialog
                  const isActive = (anchorEl?.data.name === item.name);

                  return (
                    <Box
                      key={item.name}
                      sx={{
                        backgroundColor: (isActive) ? '#fff7f4' : 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: 1,
                        '& .MuiButton-text':{
                          margin: 0,
                          border: '1px solid transparent',
                          borderRadius: 0,
                          textDecoration: 'none',
                          padding: '24px 12px',
                          '&:hover': {
                            backgroundColor: '#fff7f4',
                          }
                        },

                      }}>
                      <Button
                        theme={item.theme}
                        data-name={item.name}
                        {...action}
                        disableRipple
                      >
                        {item.name}
                      </Button>
                      {
                        (item.sub && isActive) &&
                        <Menu
                          open={openSub}
                          items={item.sub}
                          anchorEl={anchorEl?.el}
                          handleClose={handleCloseMenu}
                        />
                      }
                    </Box>
                  )
                })}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        {/* Drawer contents */}
        <Box component="nav">
          <Drawer
            anchor='top'
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: '100%',
                backgroundColor: '#fffbfa',
                borderRadius: '0 0 20px 20px',
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
      {/* Toolbar hack - sets a toolbar-sized gap at the top of the page so things don't go behind the fixed header */}
      <Toolbar />
      {bookNowDialog}
    </>
  );
})

export default Header;
