import React, { Component } from "react";
import axios from "axios";
import { TextField, Container, MenuItem, makeStyles } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import DotLoader from 'react-spinners/DotLoader';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Button from './Button';

const useStyles = makeStyles({
  root: {
    '& .MuiInputLabel-root': {
      fontFamily: 'PangramSansRounded, Helvetica, Arial, Sans-serif',
      color: '#040068',
    },
    '& .MuiOutlinedInput-root fieldset': {
      borderColor: '#040068',
      borderWidth: '2px',
      borderRadius: '10px',
    }
  }
});

const Field = (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="outlined"
      required
      type="text"
      margin="normal"
      fullWidth
      classes={{
        root: classes.root,
      }}
      InputLabelProps={{disableAnimation: true, shrink: true,}}
      {...props}
    />
  )
}

export default class ContactForm extends Component {

  state = {
    name: "",
    message: "",
    email: "",
    organisation: "",
    role: "",
    solution: "",
    users: "",
    sent: false,
    emailError: false,
    sendError: false,
    loading: false,
  };

  hideForm = () => {
    this.setState({
      name: "",
      message: "",
      email: "",
      organisation: "",
      role: "",
      solution: "",
      users: "",
      loading: false,
    });
  };

  handleChangeEmail(e) {
    if (
      !e.target.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      this.setState({
        email: e.target.value,
      });
      this.setState({ emailError: true });

      if (this.state.email === "") {
        // check if the input is empty
        this.setState({ emailError: false });
      }
    } else {
      this.setState({ email: e.target.value, emailError: false });
    }
  }

  formSubmit = async (e) => {
    e.preventDefault();

    if(this.state.loading) return;
    this.setState({loading: true});
    
    let data = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
      organisation: this.state.organisation,
      role: this.state.role,
      solution: this.state.solution,
      users: this.state.users,
    };

    this.setState({ sendError: false });

    if (`${process.env.FAKE_EMAIL}` === "true")
    {
        console.log(data);
        this.setState({ sent: true }, this.hideForm());
        return Promise.resolve();
    }
    else
    {
        return await axios.post(`${process.env.API_URL}/contactus`, data)
        .then(res => this.setState({ sent: true }, () => {
          this.hideForm();
          this.props.afterSuccess();
          }))
        .catch( err => {
          console.log(err);
          this.setState({ sendError: true, loading: false });
        });
    }
  };

  // Functions
  render() {

    return (
      <Container>
        { !this.state.sent && (
        <Container component="form" onSubmit={(e) => this.formSubmit(e)}>
          <Grid container columnSpacing={{ md: 2 }}>
            <Grid item xs={12} md={6}>
              <Field
                id="name"
                label="Your name"
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                id="email"
                label="Your email"
                value={this.state.email}
                onChange={(e) => this.handleChangeEmail(e)}
                error={this.state.emailError}
                type="email"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                id="organisation"
                label="Your organisation"
                value={this.state.organisation}
                onChange={(e) => this.setState({ organisation: e.target.value })}
                error={this.state.emailError}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                id="role"
                label="Your role"
                value={this.state.role}
                onChange={(e) => this.setState({ role: e.target.value })}
                error={this.state.emailError}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                id="solutions"
                select
                label="Solutions"
                placeholder="Select your solution"
                value={this.state.solution}
                onChange={(e) => this.setState({ solution: e.target.value })}
                error={this.state.emailError}
              >
                  <MenuItem value={'All solutions'}>All Solutions</MenuItem>
                  <MenuItem value={'Mentally Fit Organisation'}>Mentally Fit Organisation</MenuItem>
                  <MenuItem value={'Mentally Healthy Clinical Care'}>Mentally Healthy Clinical Care</MenuItem>
              </Field>
            </Grid>

            <Grid item xs={12} md={6}>
              <Field
                id="users"
                label="Estimated number of users"
                value={this.state.users}
                onChange={(e) => this.setState({ users: e.target.value })}
                error={this.state.emailError}
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <Field
                id="message"
                label="Add your message"
                multiline
                rows={6}
                maxRows={6}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />
            </Grid>

            <Box 
              sx={{
                justifyContent:"center",
                display:"flex",
                mb: 5,
                mt: 4,
                width: '100%',
              }}
            >
              {this.state.loading ?
                <DotLoader color='#FFB3FF' loading={this.state.loading} />
                :
                <Button
                type="submit"
                theme='primary'
                >
                  Send
                </Button> }
            </Box>
          </Grid>
        </Container>)}
        {this.state.sendError && (
          <Box display="flex" justifyContent="center" mb={5}>
            <Alert severity="error">We are sorry but something went wrong. You could try again or email us at support@innowell.org for help.</Alert>
          </Box>
        )}
        { this.state.sent && (
          <Box display="flex" justifyContent="center" mb={5}>
            <Alert severity="success">Thank you. You message is on its way.</Alert>
          </Box>)}
      </Container>
    );
  }
}

ContactForm.defaultProps = { afterSuccess: () => {} }